import moment from 'moment/moment'
import { DATE_FORMAT } from '../../components/formik/InputDateTime/InputDateTime.utils'

export const getSisterVesselImoList = (
  sisterVesselsData: MasterDataApi.SisterVessel.SisterVesselResponse,
) => sisterVesselsData.map(({ imoNo }) => imoNo).join(',')

export const getVesselName = (
  imoNo: number,
  sisterVesselsData: MasterDataApi.SisterVessel.SisterVesselResponse,
) => sisterVesselsData.find((v) => v.imoNo === imoNo)?.vesselName ?? '-'

export const getInitialDateRange = () => {
  const now = moment.utc()
  const to = now.clone().endOf('month')
  const from = now.clone().subtract(2, 'months').startOf('month')

  return {
    from: from.format(DATE_FORMAT),
    to: to.format(DATE_FORMAT),
  }
}
