export enum ChartType {
  Sfoc = 'sfoc',
  LoadProfile = 'load-profile',
  // Pmax = 'pmax',
  // Pcomp = 'pcomp',
}

export const performanceMetricsOptions = [
  { label: 'SFOC', value: ChartType.Sfoc },
  { label: 'Load profile', value: ChartType.LoadProfile },
  // { label: 'Pmax', value: ChartType.Pmax },
  // { label: 'Pcomp', value: ChartType.Pcomp },
]
