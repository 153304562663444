import { McButton, McIcon, McTooltip } from '@maersk-global/mds-react-wrapper'

import PerformanceGauge from './PerformanceGauge'
import {
  buildVesselsAeConsumption,
  buildVesselsMeConsumption,
  calculateVesselsConsumptionDeviation,
} from '../PerformanceOverview.utils'
import {
  HeaderWrapper,
  InnerWrapper,
  RankItem,
} from '../PerformanceOverview.styles'

type Props = {
  data: Array<BalrogApi.WasteData>
  vesselName: string
  isMainEngine?: boolean
}

const EnginePerformance = ({ vesselName, data, isMainEngine }: Props) => {
  const vesselsConsumption = isMainEngine
    ? buildVesselsMeConsumption(data)
    : buildVesselsAeConsumption(data)

  const vesselsConsumptionDeviation =
    calculateVesselsConsumptionDeviation(vesselsConsumption)

  const _title = isMainEngine ? 'Main' : 'Auxiliary'

  const ranking = [...vesselsConsumptionDeviation]
    .sort((a, b) => a.value - b.value)
    .map((x, i) => ({ ...x, rank: i + 1 }))
    .filter((x, i) => i < 3 || x.name === vesselName)

  return (
    <div>
      <HeaderWrapper>
        <h4>{_title} Engine Performance</h4>
        <McTooltip position='top-center' appearance='inverse' width={500}>
          <McButton
            slot='trigger'
            appearance='primary'
            fit='small'
            variant='plain'
          >
            <McIcon icon='info-circle' />
          </McButton>
          <span>{isMainEngine ? meTooltip : aeTooltip}</span>
        </McTooltip>
      </HeaderWrapper>
      <InnerWrapper>
        <PerformanceGauge
          vesselName={vesselName}
          data={vesselsConsumptionDeviation}
        />
        <div>
          {ranking.map((x) => (
            <RankItem key={x.name} emphasize={x.name === vesselName}>
              <span>{x.rank}.</span>
              <span>{x.name}</span>
            </RankItem>
          ))}
        </div>
      </InnerWrapper>
    </div>
  )
}

export default EnginePerformance

const meTooltip =
  'Main engine performance overview for the last three months compared to the class-specific baseline. Positive numbers mean performance below the baseline, while negative numbers mean performance above.'

const aeTooltip =
  'Auxiliary engine performance overview for the last three months compared to the shop test baseline. Positive numbers mean performance below the baseline, while negative numbers mean performance above.'
