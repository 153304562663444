import moment from 'moment'

import type {
  AggregatedWasteData,
  AggregatedWasteDataSummed,
  WasteDataset,
  WasteDatasetRow,
} from './OverallWasteChart.types'
import { MONTH_YEAR_DATE_FORMAT } from '../../../../components/formik/InputDateTime/InputDateTime.utils'
import {
  calcPercentageSafely,
  calcVectorSum,
  roundNumber,
} from '../../../../utils/math-utils'

/**
 * Compiles the chart-compatible dataset for the overall equipment waste chart by extracting,
 * combining, and aggregating the relevant data from the energy metrics and waste data endpoints.
 * */
export const resolveDataset = (
  energyMetrics: Array<BalrogApi.EnergyMetrics>,
  wasteData: Array<BalrogApi.WasteData>,
): WasteDataset => {
  // ['Vessel', 'Jan 2024', 'Feb 2024', 'Mar 2024']
  const columns = new Set<string>(['Vessel'])

  const whrAndBoilerConsumptionAndWaste = aggregateEnergyMetrics(
    energyMetrics,
    columns,
  )

  const enginesConsumptionAndWaste = aggregateWasteData(wasteData, columns)

  // Deep copy input data to avoid mutating the original objects
  const allConsumptionAndWaste = mergeAggregatedWasteData(
    JSON.parse(JSON.stringify(enginesConsumptionAndWaste)),
    JSON.parse(JSON.stringify(whrAndBoilerConsumptionAndWaste)),
  )

  sanitize(allConsumptionAndWaste)

  const allConsumptionAndWasteSummed = sumAggregatedWasteData(
    allConsumptionAndWaste,
  )

  // ["Emma Maersk", -0.4, 0.3, -0.2]
  const rows = resolveDatasetRows(allConsumptionAndWasteSummed)

  return [Array.from(columns), ...rows]
}

export const aggregateEnergyMetrics = (
  energyMetrics: Array<BalrogApi.EnergyMetrics>,
  columns: Set<string>,
): AggregatedWasteData => {
  const result: AggregatedWasteData = {}

  energyMetrics.forEach((metrics) => {
    const { imo, vesselName, date } = metrics

    const month = moment.utc(date).format('YYYY-MM')
    const waste = [metrics.boilerWasteMt, metrics.whrWasteMt]
    const consumption = [metrics.boilerConsumptionMt, metrics.whrConsumptionMt]

    aggregate(imo, vesselName, month, waste, consumption, result)

    columns.add(moment.utc(date).format(MONTH_YEAR_DATE_FORMAT))
  })

  return result
}

const aggregateWasteData = (
  wasteData: Array<BalrogApi.WasteData>,
  columns: Set<string>,
): AggregatedWasteData => {
  const result: AggregatedWasteData = {}

  wasteData.forEach((data) => {
    const { imo, vesselName, date } = data

    const month = moment.utc(date).format('YYYY-MM')
    const waste = [
      data.meWasteMt,
      data.ae1WasteMt,
      data.ae2WasteMt,
      data.ae3WasteMt,
      data.ae4WasteMt,
      data.ae5WasteMt,
    ]
    const consumption = [
      data.meConsumptionMt,
      data.ae1ConsumptionMt,
      data.ae2ConsumptionMt,
      data.ae3ConsumptionMt,
      data.ae4ConsumptionMt,
      data.ae5ConsumptionMt,
    ]

    aggregate(imo, vesselName, month, waste, consumption, result)

    columns.add(moment.utc(date).format(MONTH_YEAR_DATE_FORMAT))
  })

  return result
}

export const aggregate = (
  imo: number,
  vesselName: string,
  month: string,
  waste: Array<number | null>,
  consumption: Array<number | null>,
  result: AggregatedWasteData,
) => {
  if (imo in result) {
    if (month in result[imo].waste && month in result[imo].consumption) {
      result[imo].waste[month].push(...waste)
      result[imo].consumption[month].push(...consumption)
    } else {
      result[imo].waste[month] = waste
      result[imo].consumption[month] = consumption
    }
    result[imo].vesselName = vesselName
  } else {
    result[imo] = {
      waste: { [month]: waste },
      consumption: { [month]: consumption },
      vesselName: vesselName,
    }
  }
}

const mergeAggregatedWasteData = (
  x: AggregatedWasteData,
  y: AggregatedWasteData,
): AggregatedWasteData => {
  return Object.entries(x).reduce((acc, [imo, values]) => {
    if (imo in acc) {
      Object.entries(values.waste).forEach(([month, waste]) => {
        if (month in acc[imo].waste) {
          acc[imo].waste[month].push(...waste)
        } else {
          acc[imo].waste[month] = waste
        }
      })

      Object.entries(values.consumption).forEach(([month, consumption]) => {
        if (month in acc[imo].consumption) {
          acc[imo].consumption[month].push(...consumption)
        } else {
          acc[imo].consumption[month] = consumption
        }
      })
    } else {
      acc[imo] = values
    }

    return acc
  }, y)
}

/**
 * Sanitizes the aggregated waste data by removing invalid entries.
 *
 * This function iterates over the waste and consumption data for each vessel
 * and month, and removes entries (sets to the empty array) where main engine
 * consumption or waste is null.
 *
 * <em><strong>Note that this function assumes the order of the
 * consumption/waste items is as follows:</strong></em>
 * [boiler, whr, me, ae1, ae2, ae3, ae4, ae5]. This invariant is enforced by
 * {@link aggregateEnergyMetrics} and {@link aggregateWasteData}.
 *
 * @param x - The aggregated waste data to sanitize.
 */
export const sanitize = (x: AggregatedWasteData) => {
  Object.entries(x).forEach(([imo, values]) => {
    Object.entries(values.waste).forEach(([month, waste]) => {
      if (waste[2] === null) {
        console.info('Removing monthly entry with null main engine waste:', {
          imo,
          month,
        })
        x[imo].waste[month] = []
        x[imo].consumption[month] = []
      }
    })
    Object.entries(values.consumption).forEach(([month, consumption]) => {
      if (consumption[2] === null) {
        console.info(
          'Removing monthly entry with null main engine consumption:',
          {
            imo,
            month,
          },
        )
        x[imo].consumption[month] = []
        x[imo].waste[month] = []
      }
    })
  })
}

const sumAggregatedWasteData = (
  data: AggregatedWasteData,
): AggregatedWasteDataSummed => {
  return Object.entries(data).reduce((acc, [imo, values]) => {
    acc[imo] = {
      vesselName: values.vesselName,
      waste: {},
      consumption: {},
    }

    Object.entries(values.waste).forEach(([month, waste]) => {
      acc[imo].waste[month] = calcVectorSum(waste)
    })

    Object.entries(values.consumption).forEach(([month, consumption]) => {
      acc[imo].consumption[month] = calcVectorSum(consumption)
    })

    return acc
  }, {})
}

const resolveDatasetRows = (
  data: AggregatedWasteDataSummed,
): Array<WasteDatasetRow> => {
  return Object.entries(data).map(([imoNo, values]) => {
    const row: WasteDatasetRow = [values.vesselName]
    Object.entries(values.consumption).forEach(([month, consumption]) => {
      const waste = values.waste[month]

      const wastePct = calcPercentageSafely(waste, consumption)
      if (wastePct !== undefined) {
        row.push(roundNumber(wastePct, 2))
      } else {
        row.push(null)
      }
    })

    return row
  })
}
