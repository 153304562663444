import { McButton, McIcon, McTooltip } from '@maersk-global/mds-react-wrapper'

import {
  buildVesselsTotalConsumption,
  calculateVesselsConsumptionDeviation,
} from '../PerformanceOverview.utils'
import {
  HeaderWrapper,
  InnerWrapper,
  RankItem,
} from '../PerformanceOverview.styles'
import Podium from './RankingCardPodium/RankingCardPodium'

type Props = {
  vesselName: string
  energyMetrics: Array<BalrogApi.EnergyMetrics>
  wasteData: Array<BalrogApi.WasteData>
}

const PerformanceCard = ({ vesselName, energyMetrics, wasteData }: Props) => {
  const vesselsConsumption = buildVesselsTotalConsumption(
    energyMetrics,
    wasteData,
  )

  const vesselsConsumptionDeviation =
    calculateVesselsConsumptionDeviation(vesselsConsumption)

  const ranking = [...vesselsConsumptionDeviation]
    .sort((a, b) => a.value - b.value)
    .map((x, i) => ({ ...x, rank: i + 1 }))
    .filter((x, i) => i < 3 || x.name === vesselName)

  return (
    <>
      <div>
        <HeaderWrapper>
          <h4>Vessel Energy Efficiency</h4>
          <McTooltip position='top-center' appearance='inverse' width={500}>
            <McButton
              slot='trigger'
              appearance='primary'
              fit='small'
              variant='plain'
            >
              <McIcon icon='info-circle' />
            </McButton>
            <span>
              This figure shows the vessels best in class, which have the
              highest overall performance and fuel efficiency across all
              machinery.
            </span>
          </McTooltip>
        </HeaderWrapper>
        <InnerWrapper>
          <Podium />
          <div>
            {ranking.map((x) => (
              <RankItem key={x.name} emphasize={x.name === vesselName}>
                <span>{x.rank}.</span>
                <span>{x.name}</span>
              </RankItem>
            ))}
          </div>
        </InnerWrapper>
      </div>
    </>
  )
}

export default PerformanceCard
